/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// javascipt plugin for creating charts


// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
import {charitablePrefs, dataTable} from "variables/general";
import Chart from "chart.js";
// reactstrap components

import {
    chartOptions,
    parseOptions,
} from "variables/charts.js";

// reactstrap components
import {
    Button,
    ButtonGroup,
    UncontrolledTooltip,
    Card,
    CardHeader,
    Container,
    Row,
    Col
} from "reactstrap";
import DataService from "../../../services/DataService";

const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
        <div className="dataTables_length" id="datatable-basic_length">
            <label>
                Show{" "}
                {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={e => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="1000">1000</option>

                    </select>
                }{" "}
                entries.
            </label>
        </div>
    )
});

const { SearchBar } = Search;

class DisplayResults extends React.Component {

    dataService = new DataService();

    copyToClipboardAsTable = el => {
        var body = document.body,
            range,
            sel;
        if (document.createRange && window.getSelection) {
            range = document.createRange();
            sel = window.getSelection();
            sel.removeAllRanges();
            try {
                range.selectNodeContents(el);
                sel.addRange(range);
            } catch (e) {
                range.selectNode(el);
                sel.addRange(range);
            }
            document.execCommand("copy");
        } else if (body.createTextRange) {
            range = body.createTextRange();
            range.moveToElementText(el);
            range.select();
            range.execCommand("Copy");
        }
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{display: "block", marginTop: "-100px"}}
                    title="Good job!"
                    onConfirm={() => this.setState({alert: null})}
                    onCancel={() => this.setState({alert: null})}
                    confirmBtnBsStyle="info"
                    btnSize=""
                >
                    Copied to clipboard!
                </ReactBSAlert>
            )
        });
    }

    state = { results : null };
    componentDidMount() {
        this.dataService.retrieveCharitablePreferences().then(res =>{
            this.setState({results : res });
        })

    }

    constructor(props) {
        super(props);
        if (window.Chart) {
            parseOptions(Chart, chartOptions());



        }
    }


    parseResults()
    {
            var results = [];

            if(this.state.results) {
                this.state.results.forEach(b => {


                    results.push(
                        {
                            email: b.email,
                            dateSubmitted: b.dateSubmitted,
                            povertyPreventionOrReliefValue : b.povertyPreventionOrRelief.value,
                            educationValue : b.education.value,
                            healthAndSavingOfLivesValue :b.healthAndSavingOfLives.value,
                            citizenshipCommunityDevelopmentValue : b.citizenshipCommunityDevelopment.value,
                            artsCultureHeritageScienceValue : b.artsCultureHeritageScience.value,
                            amateurSportValue : b.amateurSport.value ,
                            humanRightsConflictResolutionReligiousOrRacialHarmonyEqualityDivValue : b.humanRightsConflictResolutionReligiousOrRacialHarmonyEqualityDiversity.value ,
                            environmentalProtectionOrImprovementValue : b.environmentalProtectionOrImprovement.value ,
                            reliefOfThoseInNeedYouthAgeIllHealthDisabilityFinancialHardshipValue : b.reliefOfThoseInNeedYouthAgeIllHealthDisabilityFinancialHardship.value,
                            armedForcesPoliceAmbulanceServicesFireAndRescueServicesValue : b.armedForcesPoliceAmbulanceServicesFireAndRescueServices.value,
                            animalWelfareValue : b.animalWelfare.value ,
                            religionValue : b.religion.value

                        })
                });
            }

            return results;
    }

// Parse global options




    render() {
        return (
            <>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader>
                                <h3 className="mb-0">Philanthropy Preference Responses</h3>
                                <p className="text-sm mb-0">
                                    The table below shows the Philanthropic preferences that you have received to date.You can also press the copy button below which will copy the results to the clipboard allowing you to export the results to excel.<br/><br/>
                                    The data is only available to specific users. If you do not have the correct permissions then you will not see any results.
                                </p>
                            </CardHeader>
                            <ToolkitProvider
                                data={this.parseResults()}
                                keyField="email"
                                columns={[
                                    {
                                        dataField: "email",
                                        text: "Email",
                                        sort: true
                                    },
                                    {
                                        dataField: "dateSubmitted",
                                        text: "Date Submitted",
                                        sort: true
                                    },
                                    {
                                        dataField: "povertyPreventionOrReliefValue",
                                        text: "Poverty Prevention",
                                        sort: true
                                    },
                                    {
                                        dataField: "educationValue",
                                        text: "Education",
                                        sort: true
                                    },
                                    {
                                        dataField: "healthAndSavingOfLivesValue",
                                        text: "Health And Saving Of Lives",
                                        sort: true
                                    },
                                    {
                                        dataField: "citizenshipCommunityDevelopmentValue",
                                        text: "Citizenship and Community Development",
                                        sort: true
                                    },
                                    {
                                        dataField: "artsCultureHeritageScienceValue",
                                        text: "Arts Culture Heritage Science",
                                        sort: true
                                    },
                                    {
                                        dataField: "amateurSportValue",
                                        text: "Amateur Sport",
                                        sort: true
                                    },
                                    {
                                        dataField: "humanRightsConflictResolutionReligiousOrRacialHarmonyEqualityDivValue",
                                        text: "Human Rights",
                                        sort: true
                                    },
                                    {
                                        dataField: "artsCultureHeritageScienceValue",
                                        text: "Arts Culture Heritage Science",
                                        sort: true
                                    },
                                    {
                                        dataField: "environmentalProtectionOrImprovementValue",
                                        text: "Environment Protection Or Improvement",
                                        sort: true
                                    },
                                    {
                                        dataField: "reliefOfThoseInNeedYouthAgeIllHealthDisabilityFinancialHardshipValue",
                                        text: "Relief Of Those In Need",
                                        sort: true
                                    },
                                    {
                                        dataField: "armedForcesPoliceAmbulanceServicesFireAndRescueServicesValue",
                                        text: "Armed Forces Police",
                                        sort: true
                                    },
                                    {
                                        dataField: "animalWelfareValue",
                                        text: "Animal Welfare",
                                        sort: true
                                    },
                                    {
                                        dataField: "religionValue",
                                        text: "Religion",
                                        sort: true
                                    }










                                ]}
                                search
                            >
                                {props => (
                                    <div className="py-4 table-responsive">
                                        <Container fluid>
                                            <Row>
                                                <Col xs={12} sm={6}>
                                                    <ButtonGroup>
                                                        <Button
                                                            className="buttons-copy buttons-html5"
                                                            color="default"
                                                            size="sm"
                                                            id="copy-tooltip"
                                                            onClick={() =>
                                                                this.copyToClipboardAsTable(
                                                                    document.getElementById("react-bs-table")
                                                                )
                                                            }
                                                        >
                                                            <span>Copy</span>
                                                        </Button>

                                                    </ButtonGroup>

                                                    <UncontrolledTooltip
                                                        placement="top"
                                                        target="copy-tooltip"
                                                    >
                                                        This will copy to your clipboard the visible rows
                                                        of the table.
                                                    </UncontrolledTooltip>
                                                </Col>
                                                <Col xs={12} sm={6}>
                                                    <div
                                                        id="datatable-basic_filter"
                                                        className="dataTables_filter px-4 pb-1 float-right"
                                                    >
                                                        <label>
                                                            Search:
                                                            <SearchBar
                                                                className="form-control-sm"
                                                                placeholder=""
                                                                {...props.searchProps}
                                                            />
                                                        </label>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                        <BootstrapTable
                                            ref={el => (this.componentRef = el)}
                                            {...props.baseProps}
                                            bootstrap4={true}
                                            pagination={pagination}
                                            bordered={false}
                                            id="react-bs-table"
                                        />
                                    </div>
                                )}
                            </ToolkitProvider>
                        </Card>
                    </div>
                </Row>

            </>
        );
    }
}
export default DisplayResults;
