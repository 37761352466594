/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
import Chart from "chart.js";
// react plugin used to create charts
import {Pie} from "react-chartjs-2";
// reactstrap components
import {
    chartOptions,
    parseOptions,
    chartExample6
} from "variables/charts.js";



// react component used to create sweet alerts

// reactstrap components
import {
    Button,
    ButtonGroup,
    UncontrolledTooltip,
    Badge,
    Card,
    CardHeader,
    CardBody,
    Progress,
    Container,
    Row,
    Col,
    Table,
    Media,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Form,
    FormGroup,
    Input,
    Label,
    ListGroup, ListGroupItem
} from "reactstrap";
import SimpleHeader from "../../../components/Headers/SimpleHeader";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import {dataTable} from "../../../variables/general";
import BootstrapTable from "react-bootstrap-table-next";
import ReactToPrint from "react-to-print";

import Slider from "nouislider";

import paginationFactory from "react-bootstrap-table2-paginator";
import DataService from "../../../services/DataService";
// core components

const povertyPreventionOrRelief = {
    name: "Poverty Prevention or Relief",
    shortName: "Poverty",
    value: 0
};


const education = {
    name: "Education",
    shortName: "Education",
    value: 0
};

const healthAndSavingOfLives = {
    name: "Health and Saving of Lives",
    shortName: "Health",
    value: 0
};


const citizenshipCommunityDevelopment = {
    name: "Citizenship, Community Development",
    shortName: "Citizenship",
    value: 0
};

const artsCultureHeritageScience = {
    name: "Arts, Culture, Heritage, Science",
    shortName: "Arts",
    value: 0
};

const amateurSport = {
    name: "Amateur Sport",
    shortName: "Amateur Sport",
    value: 0
};

const humanRightsConflictResolutionReligiousOrRacialHarmonyEqualityDiversity = {
    name: "Human Rights, Conflict Resolution, Religious or Racial Harmony, Equality, Diversity",
    shortName: "Human Rights",
    value: 0
};

const environmentalProtectionOrImprovement = {
    name: "Environmental Protection or Improvement",
    shortName: "Environmental Protection",
    value: 0
};

const reliefOfThoseInNeedYouthAgeIllHealthDisabilityFinancialHardship = {
    name: "Relief of Those in Need: youth, age, ill-health, disability, financial hardship",
    shortName: "Relief of Those in Need",
    value: 0
};

const armedForcesPoliceAmbulanceServicesFireAndRescueServices = {
    name: "Armed Forces, Police, Ambulance Services, Fire and Rescue Services",
    shortName: "Armed Forces",
    value: 0
};

const animalWelfare = {
    name: "Animal Welfare",
    shortName: "Animal Welfare",
    value: 0
};


const religion = {
    name: "Religion",
    shortName: "Religion",
    value: 0
}

const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({options, currSizePerPage, onSizePerPageChange}) => (
        <div className="dataTables_length" id="datatable-basic_length">
            <label>
                Show{" "}
                {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={e => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                }{" "}
                entries.
            </label>
        </div>
    )
});

const {SearchBar} = Search;

class CapturePreferences extends React.Component {
    dataService = new DataService();

    philanthropyPie = this.refs.philanthropyPie;

    handleEmailChange = event =>{

        this.setState({email : event.target.value});
    }

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    handleSubmit = event =>
    {
        var canvas = document.getElementById("philanthropyPie");
        var canvasData = canvas.toDataURL();

        if(this.state.email) {
            var charitablePreferences = {
                povertyPreventionOrRelief: this.state.povertyPreventionOrRelief,
                education: this.state.education,
                healthAndSavingOfLives: this.state.healthAndSavingOfLives,
                citizenshipCommunityDevelopment: this.state.citizenshipCommunityDevelopment,
                artsCultureHeritageScience: this.state.artsCultureHeritageScience,
                amateurSport: this.state.amateurSport,
                humanRightsConflictResolutionReligiousOrRacialHarmonyEqualityDiversity: this.state.humanRightsConflictResolutionReligiousOrRacialHarmonyEqualityDiversity,
                environmentalProtectionOrImprovement: this.state.environmentalProtectionOrImprovement,
                reliefOfThoseInNeedYouthAgeIllHealthDisabilityFinancialHardship: this.state.reliefOfThoseInNeedYouthAgeIllHealthDisabilityFinancialHardship,
                armedForcesPoliceAmbulanceServicesFireAndRescueServices: this.state.armedForcesPoliceAmbulanceServicesFireAndRescueServices,
                animalWelfare: this.state.animalWelfare,
                religion: this.state.religion,
                email: this.state.email,
                chart: canvasData
            }

            this.dataService.updateCharitablePreferences(charitablePreferences).then(response => {
                if (response != null) {
                    if (response.statusCode == 200) {
                        this.thankYouAlert();
                    } else {
                        this.errorAlert(response.message);
                    }
                } else {
                    this.errorAlert("Please try again later.");
                }
            });
            

            // this.dataService.retrieveCharitablePreference(this.state.email).then(res => {

            //         if(res.email === this.state.email)
            //         {
            //             this.alreadySubmittedAlert();
            //         }
            //         else
            //     {
            //         this.dataService.updateCharitablePreferences(charitablePreferences);

            //         this.thankYouAlert();
            //     }
            //     }

            // )


        }
        else
        {
            this.warningAlert();
        }
    }

    thankYouAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    style={{ display: "block", marginTop: "100px" }}
                    title="Thank you for your submission"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    btnSize=""
                    text="A few words about this sweet alert ..."
                >
                    If you can’t find the email, please check your junk folder. <br /> You can now close this page.
                </ReactBSAlert>
            )
        });
    };

    errorAlert = (message) => {
        this.setState({
            alert: (
                <ReactBSAlert
                    style={{ display: "block", marginTop: "100px" }}
                    title="We could not process your submission at this time"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    btnSize=""
                    text="A few words about this sweet alert ..."
                >
                    {message}
                </ReactBSAlert>
            )
        });
    };

    alreadySubmittedAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    style={{ display: "block", marginTop: "100px" }}
                    title="Duplicate Submission"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    btnSize=""
                    text="A few words about this sweet alert ..."
                >
                    This email has already been used to submit preferences
                </ReactBSAlert>
            )
        });
    };

    warningAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    style={{ display: "block", marginTop: "100px" }}
                    title="Email Required"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    btnSize=""
                    text="A few words about this sweet alert ..."
                >
                    Email is a required field
                </ReactBSAlert>
            )
        });
    };
    setPieData()
    {

        //only pass in labels and values that are not zero
        var labelItems = [];
        var dataItems = [];
        var pieColours = []





        if(this.state.povertyPreventionOrRelief.value > 0)
        {
            labelItems.push(this.state.povertyPreventionOrRelief.shortName);
            dataItems.push(this.state.povertyPreventionOrRelief.value);
            pieColours.push("#EDF226");
        }




        if(this.state.education.value > 0)
        {
            labelItems.push(this.state.education.shortName);
            dataItems.push(this.state.education.value);
            pieColours.push("#FFBFBD");
        }

        if(this.state.healthAndSavingOfLives.value > 0)
        {
            labelItems.push(this.state.healthAndSavingOfLives.shortName);
            dataItems.push(this.state.healthAndSavingOfLives.value);
            pieColours.push("#E543B4");
        }

        if(this.state.citizenshipCommunityDevelopment.value > 0)
        {
            labelItems.push(this.state.citizenshipCommunityDevelopment.shortName);
            dataItems.push(this.state.citizenshipCommunityDevelopment.value);
            pieColours.push("#EC1212");
        }

        if(this.state.artsCultureHeritageScience.value > 0)
        {
            labelItems.push(this.state.artsCultureHeritageScience.shortName);
            dataItems.push(this.state.artsCultureHeritageScience.value)
            pieColours.push("#963DC7");
        }

        if(this.state.amateurSport.value > 0)
        {
            labelItems.push(this.state.amateurSport.shortName);
            dataItems.push(this.state.amateurSport.value);
            pieColours.push("#D4ABE7");
        }

        if(this.state.humanRightsConflictResolutionReligiousOrRacialHarmonyEqualityDiversity.value > 0)
        {
            labelItems.push(this.state.humanRightsConflictResolutionReligiousOrRacialHarmonyEqualityDiversity.shortName);
            dataItems.push(this.state.humanRightsConflictResolutionReligiousOrRacialHarmonyEqualityDiversity.value)
            pieColours.push("#1D1EDB");
        }

        if(this.state.environmentalProtectionOrImprovement.value > 0)
        {
            labelItems.push(this.state.environmentalProtectionOrImprovement.shortName);
            dataItems.push(this.state.environmentalProtectionOrImprovement.value)
            pieColours.push("#13DC0E");
        }

        if(this.state.reliefOfThoseInNeedYouthAgeIllHealthDisabilityFinancialHardship.value > 0)
        {
            labelItems.push(this.state.reliefOfThoseInNeedYouthAgeIllHealthDisabilityFinancialHardship.shortName);
            dataItems.push(this.state.reliefOfThoseInNeedYouthAgeIllHealthDisabilityFinancialHardship.value);
            pieColours.push("#EDCFA8");
        }

        if(this.state.armedForcesPoliceAmbulanceServicesFireAndRescueServices.value > 0)
        {
            labelItems.push(this.state.armedForcesPoliceAmbulanceServicesFireAndRescueServices.shortName);
            dataItems.push(this.state.armedForcesPoliceAmbulanceServicesFireAndRescueServices.value);
            pieColours.push("#DEEAAC");

        }

        if(this.state.animalWelfare.value > 0)
        {
            labelItems.push(this.state.animalWelfare.shortName);
            dataItems.push(this.state.animalWelfare.value);
            pieColours.push("#FFC3F7");
        }

        if(this.state.religion.value > 0)
        {
            labelItems.push(this.state.religion.shortName);
            dataItems.push(this.state.religion.value)
            pieColours.push("#F38943");
        }

        var pieData =
            {
                data: {
                    labels: labelItems ,
                    datasets
    :
        [
            {
                data: dataItems ,
                backgroundColor: pieColours,
                label: "Results"
            }
        ]
    }
    ,
        options: {
            responsive: true,
                legend
        :
            {
                position: "top"
            }
        ,
            animation: {
                animateScale: true,
                    animateRotate
            :
                true
            }
        }
    };

        this.setState({pieData : pieData});

        let pie = this.reference.chartInstance
        pie.update();
    }

    getPieData()
    {
        var pieData = null;
        if(!this.state.pieData) {
            pieData =
            {
                data: {
                    labels: [
                        povertyPreventionOrRelief.shortName,
                        education.shortName,
                        healthAndSavingOfLives.shortName,
                        citizenshipCommunityDevelopment.shortName,
                        artsCultureHeritageScience.shortName,
                        amateurSport.shortName,
                        humanRightsConflictResolutionReligiousOrRacialHarmonyEqualityDiversity.shortName,
                        environmentalProtectionOrImprovement.shortName,
                        reliefOfThoseInNeedYouthAgeIllHealthDisabilityFinancialHardship.shortName,
                        armedForcesPoliceAmbulanceServicesFireAndRescueServices.shortName,
                        animalWelfare.shortName,
                        religion.shortName
                    ],
                        datasets
                :
                    [
                        {
                            data: [
                                povertyPreventionOrRelief.value,
                                education.value,
                                healthAndSavingOfLives.value,
                                citizenshipCommunityDevelopment.value,
                                artsCultureHeritageScience.value,
                                amateurSport.value,
                                humanRightsConflictResolutionReligiousOrRacialHarmonyEqualityDiversity.value,
                                environmentalProtectionOrImprovement.value,
                                reliefOfThoseInNeedYouthAgeIllHealthDisabilityFinancialHardship.value,
                                armedForcesPoliceAmbulanceServicesFireAndRescueServices.value,
                                animalWelfare.value,
                                religion.value
                            ],
                            backgroundColor: ["#7D6B2B", "#61766F","#B8CAD4","#CBC6A8","#173130","#51565A","#5E707E","#E7EBEC","#F8F8E0","#40403F","#91A5A6","#788385"],
                            label: "Results"
                        }
                    ]
                }
            ,
            options: {
                responsive: true,
                    legend
            :
                {
                    display : true,
                    position: "left"
                }
            ,
                animation: {
                    animateScale: true,
                        animateRotate
                :
                    true
                }
            }
            }
            this.setState({pieData : pieData});
        }
        else
        {
            pieData = this.state.pieData;
        }
        return pieData;
    }

    copyToClipboardAsTable = el => {
        var body = document.body,
            range,
            sel;
        if (document.createRange && window.getSelection) {
            range = document.createRange();
            sel = window.getSelection();
            sel.removeAllRanges();
            try {
                range.selectNodeContents(el);
                sel.addRange(range);
            } catch (e) {
                range.selectNode(el);
                sel.addRange(range);
            }
            document.execCommand("copy");
        } else if (body.createTextRange) {
            range = body.createTextRange();
            range.moveToElementText(el);
            range.select();
            range.execCommand("Copy");
        }
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{display: "block", marginTop: "-100px"}}
                    title="Good job!"
                    onConfirm={() => this.setState({alert: null})}
                    onCancel={() => this.setState({alert: null})}
                    confirmBtnBsStyle="info"
                    btnSize=""
                >
                    Copied to clipboard!
                </ReactBSAlert>
            )
        });
    }

    state = {
        povertyPreventionOrRelief,
        education ,
        amateurSport ,
        animalWelfare ,
        armedForcesPoliceAmbulanceServicesFireAndRescueServices ,
        artsCultureHeritageScience ,
        citizenshipCommunityDevelopment,
        environmentalProtectionOrImprovement ,
        healthAndSavingOfLives ,
        humanRightsConflictResolutionReligiousOrRacialHarmonyEqualityDiversity ,
        reliefOfThoseInNeedYouthAgeIllHealthDisabilityFinancialHardship ,
        religion ,
        email : null,
        pieData : null
    };

        componentDidMount() {

        var propertySlider  = this.refs.propertySlider;

        Slider.create(propertySlider, {
            start: [0],
            connect: [true, false],
            step: 1,
            range: { min: 0, max: 100 }
        }).on(
            "update",
            function(values, handle) {

                var someValue = this.state.povertyPreventionOrRelief;
                someValue.value =  values[0];

                this.setState({
                    povertyPreventionOrRelief: someValue
                });

                this.setPieData();


            }.bind(this)
        );


        var educationSlider  = this.refs.educationSlider;

        Slider.create(educationSlider, {
            start: [0],
            connect: [true, false],
            step: 1,
            range: { min: 0.0, max: 100.0 }
        }).on(
            "update",
            function(values, handle) {

                var someValue = this.state.education;
                someValue.value =  values[0];

                this.setState({
                    education: someValue
                });

                this.setPieData();

            }.bind(this)
        );


        var healthSlider = this.refs.healthSlider;

        Slider.create(healthSlider, {
            start: [0],
            connect: [true, false],
            step: 1,
            range: {min: 0.0, max: 100.0}
        }).on(
            "update",
            function (values, handle) {

                var someValue = this.state.healthAndSavingOfLives;
                someValue.value = values[0];

                this.setState({
                    healthAndSavingOfLives: someValue
                });

                this.setPieData();

            }.bind(this)
        );

        var citizenSlider = this.refs.citizenSlider;


        Slider.create(citizenSlider, {
            start: [0],
            connect: [true, false],
            step: 1,
            range: {min: 0.0, max: 100.0}
        }).on(
            "update",
            function (values, handle) {

                var someValue = this.state.citizenshipCommunityDevelopment;
                someValue.value = values[0];

                this.setState({
                    citizenshipCommunityDevelopment: someValue
                });

                this.setPieData();

            }.bind(this)
        );


        var artsSlider = this.refs.artsSlider;


        Slider.create(artsSlider, {
            start: [0],
            connect: [true, false],
            step: 1,
            range: {min: 0.0, max: 100.0}
        }).on(
            "update",
            function (values, handle) {

                var someValue = this.state.artsCultureHeritageScience;
                someValue.value = values[0];

                this.setState({
                    artsCultureHeritageScience: someValue
                });

                this.setPieData();

            }.bind(this)
        );

        var amateurSportSlider = this.refs.amateurSportSlider;

        Slider.create(amateurSportSlider, {
            start: [0],
            connect: [true, false],
            step: 1,
            range: {min: 0.0, max: 100.0}
        }).on(
            "update",
            function (values, handle) {

                var someValue = this.state.amateurSport;
                someValue.value = values[0];

                this.setState({
                    amateurSport: someValue
                });

                this.setPieData();

            }.bind(this)
        );

        var humanRightsSlider = this.refs.humanRightsSlider;

        Slider.create(humanRightsSlider, {
            start: [0],
            connect: [true, false],
            step: 1,
            range: {min: 0.0, max: 100.0}
        }).on(
            "update",
            function (values, handle) {

                var someValue = this.state.humanRightsConflictResolutionReligiousOrRacialHarmonyEqualityDiversity;
                someValue.value = values[0];

                this.setState({
                    humanRightsConflictResolutionReligiousOrRacialHarmonyEqualityDiversity: someValue
                });

                this.setPieData();

            }.bind(this)
        );

        var environmentalSlider = this.refs.environmentalSlider;

        Slider.create(environmentalSlider, {
            start: [0],
            connect: [true, false],
            step: 1,
            range: {min: 0.0, max: 100.0}
        }).on(
            "update",
            function (values, handle) {

                var someValue = this.state.environmentalProtectionOrImprovement;
                someValue.value = values[0];

                this.setState({
                    environmentalProtectionOrImprovement: someValue
                });

                this.setPieData();

            }.bind(this)
        );

        var reliefSlider = this.refs.reliefSlider;

        Slider.create(reliefSlider, {
            start: [0],
            connect: [true, false],
            step: 1,
            range: {min: 0.0, max: 100.0}
        }).on(
            "update",
            function (values, handle) {

                var someValue = this.state.reliefOfThoseInNeedYouthAgeIllHealthDisabilityFinancialHardship;
                someValue.value = values[0];

                this.setState({
                    reliefOfThoseInNeedYouthAgeIllHealthDisabilityFinancialHardship: someValue
                });

                this.setPieData();

            }.bind(this)
        );

        var armedForcesSlider = this.refs.armedForcesSlider;

        Slider.create(armedForcesSlider, {
            start: [0],
            connect: [true, false],
            step: 1,
            range: {min: 0.0, max: 100.0}
        }).on(
            "update",
            function (values, handle) {

                var someValue = this.state.armedForcesPoliceAmbulanceServicesFireAndRescueServices;
                someValue.value = values[0];

                this.setState({
                    armedForcesPoliceAmbulanceServicesFireAndRescueServices: someValue
                });

                this.setPieData();

            }.bind(this)
        );

        var animalWelfareSlider = this.refs.animalWelfareSlider;

        Slider.create(animalWelfareSlider, {
            start: [0],
            connect: [true, false],
            step: 1,
            range: {min: 0.0, max: 100.0}
        }).on(
            "update",
            function (values, handle) {

                var someValue = this.state.animalWelfare;
                someValue.value = values[0];

                this.setState({
                    animalWelfare: someValue
                });

                this.setPieData();

            }.bind(this)
        );


            var religionSlider = this.refs.religionSlider;

            Slider.create(religionSlider, {
                start: [0],
                connect: [true, false],
                step: 1,
                range: {min: 0.0, max: 100.0}
            }).on(
                "update",
                function (values, handle) {

                    var someValue = this.state.religion;
                    someValue.value = values[0];

                    this.setState({
                        religion: someValue
                    });

                    this.setPieData();

                }.bind(this)
            );

    }

    constructor(props) {
        super(props);
        if (window.Chart) {
            parseOptions(Chart, chartOptions());
        }
    }

// Parse global options
    render() {
        return (
            <>
                {this.state.alert}
                <Container className="mt--6" fluid>
                    <Row>
                        <div className="col">
                            <Card>
                                <CardHeader>
                                    <h3 className="mb-0">Care4Most Philanthropy Profiling Tool</h3>
                                    <p className="text-sm mb-0">
                                        Welcome to our profiling tool. By adjusting the sliders, please select the causes that are most important to you.
                                    </p>
                                </CardHeader>
                            </Card>
                        </div>
                    </Row>
                    <Row>


                        <Col lg="6">
                            <div className="card-wrapper">
                                <Card>
                                    <CardHeader>
                                        <h5 className="h3 mb-0">TAP to Select Your Preferences</h5>
                                    </CardHeader>
                                    <CardBody>
                                        <ListGroup className="list my--3" flush>
                                            <ListGroupItem className="px-0">
                                                <Row className="align-items-center">
                                                    <div className="col">
                                                        <h5>{this.state.povertyPreventionOrRelief.name}</h5>
                                                        <div className="input-slider-container">
                                                            <div className="input-slider--poverty" ref="propertySlider" />

                                                        </div>
                                                    </div>
                                                    <Col xs="4">
                                                       <h5></h5>
                                                                    <span className="range-slider-value">
                                                                      {Math.round(this.state.povertyPreventionOrRelief.value)}
                                                                    </span>
                                                    </Col>
                                                </Row>
                                            </ListGroupItem>
                                            <ListGroupItem className="px-0">
                                                <Row className="align-items-center">
                                                    <div className="col">
                                                        <h5>{this.state.education.name}</h5>
                                                        <div className="input-slider-container">
                                                            <div className="input-slider--education" ref="educationSlider" />

                                                        </div>
                                                    </div>
                                                    <Col xs="4">
                                                        <h5></h5>
                                                        <span className="range-slider-value">
                                                                      {Math.round(this.state.education.value)}
                                                                    </span>
                                                    </Col>
                                                </Row>
                                            </ListGroupItem>
                                            <ListGroupItem className="px-0">
                                                <Row className="align-items-center">
                                                    <div className="col">
                                                        <h5>{this.state.healthAndSavingOfLives.name}</h5>
                                                        <div className="input-slider-container">
                                                            <div className="input-slider--healthAndSavingOfLives" ref="healthSlider" />

                                                        </div>
                                                    </div>
                                                    <Col xs="4">
                                                        <h5></h5>
                                                        <span className="range-slider-value">
                                                                      {Math.round(this.state.healthAndSavingOfLives.value)}
                                                                    </span>
                                                    </Col>
                                                </Row>
                                            </ListGroupItem>
                                            <ListGroupItem className="px-0">
                                                <Row className="align-items-center">
                                                    <div className="col">
                                                        <h5>{this.state.citizenshipCommunityDevelopment.name}</h5>
                                                        <div className="input-slider-container">
                                                            <div className="input-slider--citizenshipCommunityDevelopment" ref="citizenSlider" />

                                                        </div>
                                                    </div>
                                                    <Col xs="4">
                                                        <h5></h5>
                                                        <span className="range-slider-value">
                                                                      {Math.round(this.state.citizenshipCommunityDevelopment.value)}
                                                                    </span>
                                                    </Col>
                                                </Row>
                                            </ListGroupItem>
                                            <ListGroupItem className="px-0">
                                                <Row className="align-items-center">
                                                    <div className="col">
                                                        <h5>{this.state.artsCultureHeritageScience.name}</h5>
                                                        <div className="input-slider-container">
                                                            <div className="input-slider--artsCultureHeritageScience" ref="artsSlider" />

                                                        </div>
                                                    </div>
                                                    <Col xs="4">
                                                        <h5></h5>
                                                        <span className="range-slider-value">
                                                                      {Math.round(this.state.artsCultureHeritageScience.value)}
                                                                    </span>
                                                    </Col>
                                                </Row>
                                            </ListGroupItem>
                                            <ListGroupItem className="px-0">
                                                <Row className="align-items-center">
                                                    <div className="col">
                                                        <h5>{this.state.amateurSport.name}</h5>
                                                        <div className="input-slider-container">
                                                            <div className="input-slider--amateurSport" ref="amateurSportSlider" />

                                                        </div>
                                                    </div>
                                                    <Col xs="4">
                                                        <h5></h5>
                                                        <span className="range-slider-value">
                                                                      {Math.round(this.state.amateurSport.value)}
                                                                    </span>
                                                    </Col>
                                                </Row>
                                            </ListGroupItem>
                                            <ListGroupItem className="px-0">
                                                <Row className="align-items-center">
                                                    <div className="col">
                                                        <h5>{this.state.humanRightsConflictResolutionReligiousOrRacialHarmonyEqualityDiversity.name}</h5>
                                                        <div className="input-slider-container">
                                                            <div className="input-slider--humanRightsConflictResolutionReligiousOrRacialHarmonyEqualityDiversity" ref="humanRightsSlider" />

                                                        </div>
                                                    </div>
                                                    <Col xs="4">
                                                        <h5></h5>
                                                        <span className="range-slider-value">
                                                                      {Math.round(this.state.humanRightsConflictResolutionReligiousOrRacialHarmonyEqualityDiversity.value)}
                                                                    </span>
                                                    </Col>
                                                </Row>
                                            </ListGroupItem>
                                            <ListGroupItem className="px-0">
                                                <Row className="align-items-center">
                                                    <div className="col">
                                                        <h5>{this.state.environmentalProtectionOrImprovement.name}</h5>
                                                        <div className="input-slider-container">
                                                            <div className="input-slider--environmentalProtectionOrImprovement" ref="environmentalSlider" />

                                                        </div>
                                                    </div>
                                                    <Col xs="4">
                                                        <h5></h5>
                                                        <span className="range-slider-value">
                                                                      {Math.round(this.state.environmentalProtectionOrImprovement.value)}
                                                                    </span>
                                                    </Col>
                                                </Row>
                                            </ListGroupItem>
                                            <ListGroupItem className="px-0">
                                                <Row className="align-items-center">
                                                    <div className="col">
                                                        <h5>{this.state.reliefOfThoseInNeedYouthAgeIllHealthDisabilityFinancialHardship.name}</h5>
                                                        <div className="input-slider-container">
                                                            <div className="input-slider--reliefOfThoseInNeedYouthAgeIllHealthDisabilityFinancialHardship" ref="reliefSlider" />
                                                        </div>
                                                    </div>
                                                    <Col xs="4">
                                                        <h5></h5>
                                                        <span className="range-slider-value">
                                                                      {Math.round(this.state.reliefOfThoseInNeedYouthAgeIllHealthDisabilityFinancialHardship.value)}
                                                                    </span>
                                                    </Col>
                                                </Row>
                                            </ListGroupItem>
                                            <ListGroupItem className="px-0">
                                                <Row className="align-items-center">
                                                    <div className="col">
                                                        <h5>{this.state.armedForcesPoliceAmbulanceServicesFireAndRescueServices.name}</h5>
                                                        <div className="input-slider-container">
                                                            <div className="input-slider--armedForcesPoliceAmbulanceServicesFireAndRescueServices" ref="armedForcesSlider" />

                                                        </div>
                                                    </div>
                                                    <Col xs="4">
                                                        <h5></h5>
                                                        <span className="range-slider-value">
                                                                      {Math.round(this.state.armedForcesPoliceAmbulanceServicesFireAndRescueServices.value)}
                                                                    </span>
                                                    </Col>
                                                </Row>
                                            </ListGroupItem>
                                            <ListGroupItem className="px-0">
                                                <Row className="align-items-center">
                                                    <div className="col">
                                                        <h5>{this.state.animalWelfare.name}</h5>
                                                        <div className="input-slider-container">
                                                            <div className="input-slider--animalWelfare" ref="animalWelfareSlider" />

                                                        </div>
                                                    </div>
                                                    <Col xs="4">
                                                        <h5></h5>
                                                        <span className="range-slider-value">
                                                                      {Math.round(this.state.animalWelfare.value)}
                                                                    </span>
                                                    </Col>
                                                </Row>
                                            </ListGroupItem>
                                            <ListGroupItem className="px-0">
                                                <Row className="align-items-center">
                                                    <div className="col">
                                                        <h5>{this.state.religion.name}</h5>
                                                        <div className="input-slider-container">
                                                            <div className="input-slider--religion" ref="religionSlider" />

                                                        </div>
                                                    </div>
                                                    <Col xs="4">
                                                        <h5></h5>
                                                        <span className="range-slider-value">
                                                                      {Math.round(this.state.religion.value)}
                                                                    </span>
                                                    </Col>
                                                </Row>
                                            </ListGroupItem>
                                        </ListGroup>
                                    </CardBody>
                                </Card>

                            </div>
                        </Col>
                        <Col xl="6">
                            <Card>
                                <CardHeader>

                                    <h5 className="h3 mb-0">Your Prioritised Preferences</h5>
                                </CardHeader>
                                <CardBody>
                                    <div className="chart">
                                        <Pie
                                            data={this.getPieData().data}
                                            options={this.getPieData().options}
                                            className="chart-canvas"
                                            id="philanthropyPie"
                                            ref = {(reference) => this.reference = reference}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardHeader>
                                    <h3 className="mb-0">Save Your Preferences</h3>
                                    <p className="text-sm mb-0">
                                        Please enter your email address in the box below and we will email you a copy of your pie chart.
                                    </p>
                                </CardHeader>
                                <CardBody>
                                    <Form>
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="exampleFormControlInput1"
                                            >
                                                Email address
                                            </label>
                                            <Input
                                                id="exampleFormControlInput1"
                                                placeholder="name@example.com"
                                                type="email" onChange={this.handleEmailChange}
                                            />

                                        </FormGroup>
                                        <Button className="btn-icon" color="primary" type="button" onClick={this.handleSubmit}>
                                                    <span className="btn-inner--icon mr-1">
                                                      <i className="ni ni-bag-17"/>
                                                    </span>
                                            <span className="btn-inner--text">Submit Your Choices</span>
                                        </Button>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default CapturePreferences;
