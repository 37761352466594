import Configuration from "../configuration/Configuration";
class DataService {
  constructor() {
    this.config = new Configuration();
  }
  async retrieveBands(userid = "") {
    //TODO: Need to filter based on user ID but that's not supported in the API
    return fetch(this.config.BANDS_COLLECTION_URL, {
      credentials: "include",
      mode: 'cors',
    }).then(response => {
      if (!response.ok) {
        this.handleResponseError(response);
      }
      if (
        response.redirected === true &&
          response.url.indexOf("/signin") !== -1
      ) {
        window.location.href = "/auth/login";
      } else {
        return response.json();
      }
    });
  }

    async updateCharitablePreferences(item) {
        console.log("ItemService.updateItem():");
        console.log(item);
        console.log(JSON.stringify(item));



        
        return fetch(this.config.PREFERENCES_COLLECTION_URL, {
            method: "PUT",
            mode: "cors",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(item)
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                if (
                    response.redirected === true &&
                    response.url.indexOf("/signin") !== -1
                ) {
                    window.location.href = "/auth/login";
                } else {
                    return response.json();
                }
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    async retrieveCharitablePreferences() {
        //TODO: Need to filter based on user ID but that's not supported in the API
        return fetch(this.config.PREFERENCES_COLLECTION_URL, {
            credentials: "include",
            mode: 'cors',
        }).then(response => {
            if (!response.ok) {
                this.handleResponseError(response);
            }
            if (
                response.redirected === true &&
                response.url.indexOf("/signin") !== -1
            ) {
                window.location.href = "/auth/login";
            } else {

                return response.json();
            }
        });
    }


    async retrieveCharitablePreference(email) {
        //TODO: Need to filter based on user ID but that's not supported in the API
        return fetch(this.config.PREFERENCES_COLLECTION_URL+"/"+email, {
            credentials: "include",
            mode: 'cors',
        }).then(response => {
            if (!response.ok) {
                this.handleResponseError(response);
            }
            if (
                response.redirected === true &&
                response.url.indexOf("/signin") !== -1
            ) {
                window.location.href = "/auth/login";
            } else {

                return response.json();
            }
        });
    }
    async retrieveTracks() {
        return fetch(this.config.TRACKS_COLLECTION_URL, {
            credentials: "include",
            mode: 'cors',
        }).then(response => {
            if (!response.ok) {
                this.handleResponseError(response);
            }
            if (
                response.redirected === true &&
                response.url.indexOf("/signin") !== -1
            ) {
                window.location.href = "/auth/login";
            } else {
                return response.json();
            }
        });
    }
  async retrieveUsers() {
        return fetch(this.config.USERS_COLLECTION_URL, {
            credentials: "include",
            mode: 'cors',
        }).then(response => {
            if (!response.ok) {
                this.handleResponseError(response);
            }
            if (
                response.redirected === true &&
                response.url.indexOf("/signin") !== -1
            ) {
                window.location.href = "/auth/login";
            } else {
                return response.json();
            }
        });
    }
  async retrieveEvents(userid = "") {
        //TODO: Need to filter based on user ID but that's not supported in the API
        return fetch(this.config.EVENTS_COLLECTION_URL, {
            credentials: "include",
            mode: 'cors',
        }).then(response => {
            if (!response.ok) {
                this.handleResponseError(response);
            }
            if (
                response.redirected === true &&
                response.url.indexOf("/signin") !== -1
            ) {
                window.location.href = "/auth/login";
            } else {
                return response.json();
            }
        });
    }

    async retrieveMyEvents(userid = "") {
        //TODO: Need to filter based on user ID but that's not supported in the API
        return fetch(this.config.USERS_COLLECTION_URL + "/" + userid + "/events", {
            credentials: "include",
            mode: 'cors',
        }).then(response => {
            if (!response.ok) {
                this.handleResponseError(response);
            }
            if (
                response.redirected === true &&
                response.url.indexOf("/signin") !== -1
            ) {
                window.location.href = "/auth/login";
            } else {
                return response.json();
            }
        });
    }

    async retrieveMyBands(userid = "") {
        //TODO: Need to filter based on user ID but that's not supported in the API
        return fetch(this.config.USERS_COLLECTION_URL + "/" + userid + "/bands", {
            credentials: "include",
            mode: 'cors',
        }).then(response => {
            if (!response.ok) {
                this.handleResponseError(response);
            }
            if (
                response.redirected === true &&
                response.url.indexOf("/signin") !== -1
            ) {
                window.location.href = "/auth/login";
            } else {
                return response.json();
            }
        });
    }

  async retrieveMetaData() {
        return fetch(this.config.META_DATA_URL, {
            credentials: "include",
            mode: 'cors',
        }).then(response => {
            if (!response.ok) {
                this.handleResponseError(response);
            }
            if (
                response.redirected === true &&
                response.url.indexOf("/signin") !== -1
            ) {
                window.location.href = "/auth/login";
            } else {
                return response.json();
            }
        });
    }
  async getBand(itemLink) {
    console.log("ItemService.getItem():");
    console.log("Item: " + this.config.BANDS_COLLECTION_URL + "/" + itemLink);
    return fetch(this.config.BANDS_COLLECTION_URL + "/" + itemLink, {
      credentials: "include"
    })
      .then(response => {
        if (!response.ok) {
          this.handleResponseError(response);
        }
        if (
          response.redirected === true &&
            response.url.indexOf("/signin") !== -1
        ) {
          window.location.href = "/auth/login";
        } else {
          return response.json();
        }
      })
      .catch(error => {
        this.handleError(error);
      });
  }
    async getTrack(itemLink) {
        console.log("ItemService.getItem():");
        console.log("Item: " + this.config.TRACKS_COLLECTION_URL + "/" + itemLink);
        return fetch(this.config.TRACKS_COLLECTION_URL + "/" + itemLink, {
            credentials: "include"
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                if (
                    response.redirected === true &&
                    response.url.indexOf("/signin") !== -1
                ) {
                    window.location.href = "/auth/login";
                } else {
                    return response.json();
                }
            })
            .catch(error => {
                this.handleError(error);
            });
    }
  async getEvent(itemLink) {
        console.log("ItemService.getItem():");
        console.log("Item: " + this.config.EVENTS_COLLECTION_URL + "/" + itemLink);
        return fetch(this.config.EVENTS_COLLECTION_URL + "/" + itemLink, {
            credentials: "include"
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                if (
                    response.redirected === true &&
                    response.url.indexOf("/signin") !== -1
                ) {
                    window.location.href = "/auth/login";
                } else {
                    return response.json();
                }
            })
            .catch(error => {
                this.handleError(error);
            });
    }
  async getSingleUser(itemLink) {
        console.log("ItemService.getItem():");
        console.log("Item: " + this.config.USERS_COLLECTION_URL + "/" + itemLink);
        return fetch(this.config.USERS_COLLECTION_URL + "/" + itemLink, {
            credentials: "include"
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                if (
                    response.redirected === true &&
                    response.url.indexOf("/signin") !== -1
                ) {
                    window.location.href = "/auth/login";
                } else {
                    return response.json();
                }
            })
            .catch(error => {
                this.handleError(error);
            });
    }
  async getUser() {
    console.log("UserService.getItem():");

    return fetch(this.config.USER_URL, {
      credentials: "include"
    })
      .then(response => {
        if (!response.ok) {
          this.handleResponseError(response);
        }
        if (
          response.redirected === true &&
            response.url.indexOf("/signin") !== -1
        ) {
          window.location.href = "/auth/login";
        } else {
          return response.json();
        }
      })
      .catch(error => {
        this.handleError(error);
      });
  }

    async getUserById(id) {
        console.log("UserService.getItem():");

        return fetch(this.config.USERS_COLLECTION_URL+ "/"+ id, {
            credentials: "include"
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                if (
                    response.redirected === true &&
                    response.url.indexOf("/signin") !== -1
                ) {
                    window.location.href = "/auth/login";
                } else {
                    return response.json();
                }
            })
            .catch(error => {
                this.handleError(error);
            });
    }
  async createUser(user) {
    console.log("ItemService.createItem():");

    return fetch(this.config.USER_URL, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      body: JSON.stringify(user),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        if (!response.ok) {
          this.handleResponseError(response);
        }
        if (
          response.redirected === true &&
            response.url.indexOf("/signin") !== -1
        ) {
          window.location.href = "/auth/login";
        } else {
          return response.json();
        }
      })
      .catch(error => {
        this.handleError(error);
      });
  }
    async deleteUser(item) {
        console.log("ItemService.deleteItem():");
        console.log("item: " + item);
        return fetch(this.config.USERS_COLLECTION_URL + "/" + item.id, {
            method: "DELETE",
            mode: "cors",
            credentials: "include"
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
            })
            .catch(error => {
                this.handleError(error);
            });
    }
  async deleteBand(item) {
    console.log("ItemService.deleteItem():");
    console.log("item: " + item);
    return fetch(this.config.BANDS_COLLECTION_URL + "/" + item.id, {
      method: "DELETE",
      mode: "cors",
      credentials: "include"
    })
      .then(response => {
        if (!response.ok) {
          this.handleResponseError(response);
        }
      })
      .catch(error => {
        this.handleError(error);
      });
  }

    async deleteTrack(item) {
        console.log("ItemService.deleteItem():");
        console.log("item: " + item);
        return fetch(this.config.TRACKS_COLLECTION_URL + "/" + item.id, {
            method: "DELETE",
            mode: "cors",
            credentials: "include"
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
            })
            .catch(error => {
                this.handleError(error);
            });
    }


    async deleteEvent(item) {
        console.log("ItemService.deleteItem():");
        console.log("item: " + item);
        return fetch(this.config.EVENTS_COLLECTION_URL + "/" + item.id, {
            method: "DELETE",
            mode: "cors",
            credentials: "include"
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
            })
            .catch(error => {
                this.handleError(error);
            });
    }
  async updateBand(item) {
    console.log("ItemService.updateItem():");
    console.log(item);
    return fetch(this.config.BANDS_COLLECTION_URL, {
      method: "PUT",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(item)
    })
      .then(response => {
        if (!response.ok) {
          this.handleResponseError(response);
        }
        if (
          response.redirected === true &&
          response.url.indexOf("/signin") !== -1
        ) {
          window.location.href = "/auth/login";
        } else {
          return response.json();
        }
      })
      .catch(error => {
        this.handleError(error);
      });
  }
  async createBand(item) {
        console.log("ItemService.updateItem():");
        console.log(item);
        return fetch(this.config.BANDS_COLLECTION_URL, {
            method: "POST",
            mode: "cors",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(item)
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                if (
                    response.redirected === true &&
                    response.url.indexOf("/signin") !== -1
                ) {
                    window.location.href = "/auth/login";
                } else {
                    return response.json();
                }
            })
            .catch(error => {
                this.handleError(error);
            });
    }
  async updateEvent(item, band) {
      item.bandId=band;
        console.log("ItemService.updateItem():");
        console.log(item);
        return fetch(this.config.EVENTS_COLLECTION_URL, {
            method: "PUT",
            mode: "cors",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(item)
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                if (
                    response.redirected === true &&
                    response.url.indexOf("/signin") !== -1
                ) {
                    window.location.href = "/auth/login";
                } else {
                    return response.json();
                }
            })
            .catch(error => {
                this.handleError(error);
            });
    }
  async updateUser(item) {
        console.log("ItemService.updateItem():");
        console.log(item);
        return fetch(this.config.USERS_COLLECTION_URL, {
            method: "PUT",
            mode: "cors",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(item)
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                if (
                    response.redirected === true &&
                    response.url.indexOf("/signin") !== -1
                ) {
                    window.location.href = "/auth/login";
                } else {
                    return response.json();
                }
            })
            .catch(error => {
                this.handleError(error);
            });
    }


    async updateTrack(item) {
        console.log("ItemService.updateItem():");
        console.log(item);
        return fetch(this.config.TRACKS_COLLECTION_URL, {
            method: "PUT",
            mode: "cors",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(item)
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                if (
                    response.redirected === true &&
                    response.url.indexOf("/signin") !== -1
                ) {
                    window.location.href = "/auth/login";
                } else {
                    return response.json();
                }
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    async uploadFile(file) {
        console.log("ItemService.updateItem():");
        console.log(file);

        const formData = new FormData();
        formData.append(file.name , file);



        return fetch(this.config.UPLOAD_COLLECTION_URL, {
            method: "POST",
            mode: "cors",
            credentials: "include",

            body: formData
        })
            .then(response => {
                if (!response.ok) {

                    this.handleResponseError(response);
                }
                if (
                    response.redirected === true &&
                    response.url.indexOf("/signin") !== -1
                ) {
                    window.location.href = "/auth/login";
                } else {

                    return response.json();
                }
            })
            .catch(error => {
                this.handleError(error);
            });
    }



  async createEvent(item,band) {
       item.bandId=band;
        console.log(item);
        return fetch(this.config.EVENTS_COLLECTION_URL, {
            method: "POST",
            mode: "cors",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(item)
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                if (
                    response.redirected === true &&
                    response.url.indexOf("/signin") !== -1
                ) {
                    window.location.href = "/auth/login";
                } else {
                    return response.json();
                }
            })
            .catch(error => {
                this.handleError(error);
            });
    }

  handleResponseError(response) {
    throw new Error("HTTP error, status = " + response.status + response);
  }
  handleError(error) {
    console.log(error.message);
  }
}

export default DataService;
