/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react library for routing
import { Link } from "react-router-dom";
// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Button,
  Collapse,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  UncontrolledDropdown,
  DropdownToggle, DropdownMenu, ListGroup, ListGroupItem, DropdownItem, Media
} from "reactstrap";
import classnames from "classnames";

class AdminNavbar extends React.Component {
  render() {
    return (
      <>
          <Container fluid>
            <Collapse navbar isOpen={true}>


              <Nav className="align-items-center ml-auto ml-md-0" navbar>

              </Nav>
            </Collapse>
          </Container>

      </>
    );
  }
}

export default AdminNavbar;
