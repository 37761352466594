/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.js";
import Configuration from "../../../configuration/Configuration";
import DataService from "../../../services/DataService";

class Login extends React.Component{
dataService = new DataService();
  state = {};
  render() {
    const config = new Configuration();
    const redirectURL = config.OAUTH2_LOGIN_REDIRECT_URL;
    const signin = config.OAUTH2_LOGIN_URL;
    return (
      <>
        <AuthHeader
          title="Welcome!"
          lead="Please login with your Google account"
        />
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-secondary border-0 mb-0">
                <CardHeader className="bg-transparent pb-5">
                  <div className="text-muted text-center mt-2 mb-3">
                    <small>Sign in with</small>
                  </div>
                  <div className="btn-wrapper text-center">
                    <Form className="form" action={signin} method="post" style={{textAlign:"center"}}>
                      <input type="hidden" name="Provider" value="Google"/>
                      <input type="hidden" name="ReturnUrl" value={redirectURL}/>
                      <Button color="default"
                              type="submit"
                              className="btn-neutral btn-icon"
                              color="default">
                      <span className="btn-inner--icon mr-1">
                        <img
                            alt="..."
                            src={require("assets/img/icons/common/google.svg")}
                        />
                      </span>
                      <span className="btn-inner--text">Google</span>
                      </Button>
                    </Form>
                  </div>
                </CardHeader>

              </Card>

            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Login;
