class Configuration {
  DOMAIN  = "https://care4mostapi.azurewebsites.net/";
  BANDS_COLLECTION_URL = this.DOMAIN + "bands";
  PREFERENCES_COLLECTION_URL = this.DOMAIN + "CharitablePreferences";
  UPLOAD_COLLECTION_URL = this.DOMAIN + "upload"
  TRACKS_COLLECTION_URL = this.DOMAIN + "guidetracks";
  USERS_COLLECTION_URL = this.DOMAIN + "users";
  EVENTS_COLLECTION_URL = this.DOMAIN + "events";
  META_DATA_URL = this.DOMAIN + "metadata";
  USER_URL =this.DOMAIN + "user";
  OAUTH2_LOGOFF_URL = this.DOMAIN + "signout?returnUrl=https://www.care4most.org";
  OAUTH2_LOGIN_URL= this.DOMAIN + "signin";
  OAUTH2_LOGIN_REDIRECT_URL = "https://www.care4most.org/admin/displayresults";
  OAUTH2_REGISTER_REDIRECT_URL = "https://www.care4most.org";
  SIGNALLING_SERVER = "xaura.onlinedemo.space:1935";
  SIGNALLING_SERVER_HTTP = "https://xaura.onlinedemo.space:8300/";
  GUIDE_TRACK_STORAGE_LOCATION = "https://gigartydata.blob.core.windows.net/guide/";
}
export default Configuration;
